<template>
  <div>
    <div v-if="proFolder.isInError" class="alert alert-info alert-close">
      <p>
        Ce dossier n'est pas totalement clôturé. Merci de
        <a href="#" @click="handleClosingFolder" class="action" data-action="closeFolder"
           data-prevent="true">
          <strong>relancer l'opération</strong>
        </a>
        .
      </p>
    </div>

    <div id="close" v-if="proFolder.isInClosing">
      <div class="alert alert-danger text-center">
        <p>
          <img src="" alt="Veuillez patienter pendant la génération des données."/>
        </p>
        <p>
          <strong>
            Veuillez patienter pendant la génération des données qui peut prendre plusieurs minutes. Vous pouvez quitter
            cette page.
          </strong>
        </p>
      </div>
    </div>

    <folder-card :title="title" :show-action="!proFolder.isInClosing">
      <template v-slot:actions v-if="isGranted('pro-risks-dashboard', 1)">
        <el-dropdown-item>
          <a :href="generateEmpr1tePath('risques-professionnels/previsualisation/' + proFolder.id)"
             target="_blank">Prévisualiser le bilan complet</a>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link :to="{ name: 'pro-risks-synthesis' }">Voir la synthèse</router-link>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link :to="{ name: 'pro-risks-unit-matrix' }">Voir la matrice</router-link>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link :to="{ name: 'pro-risks-action-plan' }">Voir le plan d'actions</router-link>
        </el-dropdown-item>
        <el-dropdown-item @click.native="downloadPreviewPDF">
          Télécharger le bilan en PDF
        </el-dropdown-item>
        <el-dropdown-item v-if="isGranted('pro-risks-dashboard', 2)" divided
                          @click.native="editProFolder">
          Modifier
        </el-dropdown-item>
        <el-dropdown-item v-if="isGranted('pro-risks-dashboard', 2)"
                          @click.native="answerCloseProFolder">
          Clôturer
        </el-dropdown-item>
      </template>

      <template v-slot:content-top>
        <div class="col-sm-8 vcenter">
          <ul>
            <li class="no-border">
              <div class="pull-right">Nombre de risques : {{ risksCount }}</div>
              <router-link :to="{ name: 'pro-risks-identification' }">
                <font-awesome-icon icon="search"/>
                Identification des risques
              </router-link>
            </li>
            <li>
              <div class="pull-right">
                Saisie effectuée : {{ percentMade }}%
              </div>
              <router-link :to="{ name: 'pro-risks-cotation' }">
                <font-awesome-icon icon="tachometer-alt"/>
                Cotation des risques
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-sm-4 doughnut-contain pie-vcenter">
          <div class="pie-chart pie-parent">
            <div style="width: 111px; height: 111px; position:relative;">
              <risks-chart :dataset="risksChart"></risks-chart>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:content-bottom>
        <div class="col-sm-8 vcenter">
          <ul>
            <li>
              <div class="pull-right">
                Action(s) : {{ actionsCount }}
              </div>
              <router-link :to="{name: 'pro-risks-action-plan'}">
                <font-awesome-icon icon="road"/>
                Plan d'actions
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-sm-4 doughnut-contain pie-vcenter">
          <div class="pie-chart pie-parent">
            <div style="width: 111px; height: 111px; position: relative;">
              <action-plans-chart :dataset="actionPlansChart"></action-plans-chart>
            </div>
          </div>
        </div>
      </template>
    </folder-card>

    <pro-folder-edit-modal :show-modal.sync="showModal"
                           :pro-folder="proFolder"></pro-folder-edit-modal>
  </div>
</template>

<style type="text/css">
.el-date-editor.el-input {
  width: 100%;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import FolderCard from '@/components/dashboard/FolderCard.vue';
import RisksChart from '@/components/chart/RisksChart';
import ActionPlansChart from '@/components/chart/ActionPlansChart';
import ProFolderEditModal from '@modules/professional-risk/components/modal/ProFolderEditModal.vue';
import ProFolder from '@models/proRisk/ProFolder';

const proRiskModule = namespace('riskPro');

@Component({
  components: {
    ProFolderEditModal,
    FolderCard,
    RisksChart,
    ActionPlansChart,
  },
})

export default class ProFolderCard extends Vue {
  @Prop({ type: Object, required: true })
  proFolder!: ProFolder;

  @Prop({ type: String })
  title!: string;

  @proRiskModule.Action('getPreviewPDF')
  getPreviewPDF!: (id: any) => any;

  @proRiskModule.Action('closeProFolder')
  closeProFolder!: (id: number) => any;

  showModal: boolean = false;

  editProFolder() {
    this.showModal = true;
  }

  answerCloseProFolder() {
    this.$confirm('Attention, cette action ne peut pas être annulée. Confirmez-vous la clôture du dossier ?', 'Attention', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Annuler',
      type: 'warning',
    }).then(async (response) => {
      if (!this.proFolder.endAt || !this.proFolder.beginAt) {
        this.$message({
          type: 'info',
          message: 'Vous devez saisir les dates de début et de fin de ce dossier afin poursuivre sa clôture.',
        });
      } else {
        await this.handleClosingFolder();
      }

      return response;
    }).catch((error) => {
      this.$message({
        type: 'info',
        message: 'Demande annulée',
      });
      throw error;
    });
  }

  get risksCount(): number {
    return this.proFolder.nbRisks;
  }

  get percentMade(): number {
    return this.proFolder.quotationCompletion;
  }

  get actionsCount(): number {
    return this.proFolder.nbActions;
  }

  get risksChart(): Record<number, number> {
    return this.proFolder.quotationDetails;
  }

  get actionPlansChart(): Record<number, number> {
    return this.proFolder.actionsDetails;
  }

  downloadPreviewPDF() {
    this.getPreviewPDF(this.proFolder.id);
  }

  handleClosingFolder() {
    return this.closeProFolder(this.proFolder.id ? this.proFolder.id : 0)
      .then((response: any) => {
        this.$emit('closed');
        return response;
      })
      .catch((error: any) => {
        this.$emit('closed-error');
      });
  }
}
</script>
